import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { CyptolandingComponent } from './cyptolanding/cyptolanding.component';
import { Page404Component } from './extrapages/page404/page404.component';
import {PrivacyComponent} from './extrapages/privacy/privacy.component'
import {TermsComponent} from './extrapages/terms/terms.component'
import { LoginComponent } from './account/auth/login/login.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('../@main/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, loadChildren: () => import('../app/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard]},
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: '**', component: Page404Component },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true, scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
