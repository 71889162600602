import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  loginMethod(body) {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    return this.http.post(`${this.baseUrl}v1/user/login/`, body, { headers });
  }

  loginMethod1(body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    return this.http.post(`${this.baseUrl}v1/user/login/`, body, { headers }).pipe(catchError(this.handleError));
  }

  passwordResetMethod(body) {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    return this.http.post(`${this.baseUrl}v1/user/forget-password-request/`, body, { headers });
  }
  passwordOtpMethod(body) {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    return this.http.post(`${this.baseUrl}v1/user/forget-password/`, body, { headers });
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
