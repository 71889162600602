<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to Virsa-India.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo.png" alt="" class="rounded-circle" height="34">
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="mb-3">
                  <label for="username">USERNAME</label>
                  <input type="text" formControlName="username" class="form-control" id="username" placeholder="username"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                    <div *ngIf="f.username.errors.username">Username must be a valid username</div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input  [type]="hide ? 'password' : 'text'" class="form-control" formControlName="password" placeholder="Enter password"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" aria-label="Password"
                      aria-describedby="password-addon">

                    <button class="btn btn-light ms-0" type="button" (click)="showPassword()" id="password-addon"><i
                        class="mdi mdi-eye-outline"></i></button>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <span *ngIf="f.password.errors.required">Password is required</span>
                    </div>
                  </div>
                </div>

                <ngb-alert [dismissible]="false" type="danger" *ngIf="!staticAlertShow" (close)="staticAlertShow=true">
                  {{ successMessage }}
                </ngb-alert>
            
                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" type="submit">Log In</button>
                </div>
               
                <div class="mt-4 text-center">
                  <a routerLink="/account/reset-password" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Forgot
                    your
                    password?</a>
                </div>
              </form>
            </div>

          </div>
        </div>
      

      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->
