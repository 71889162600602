import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  error = '';
  returnUrl: string;
  hide: boolean = true;
  // set the current year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService, private auth: AuthService, private http: HttpClient) { }

  staticAlertShow = true;
  successMessage = '';
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }
  showPassword() {
    this.hide = !this.hide;
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  detail;
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      const body = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
        access_type: 'Admin',
      };
      this.auth.loginMethod(body).subscribe((res: any) => {
        if (res.error === false) {
          localStorage.setItem('token', res.details.credentials.access_token);
          localStorage.setItem('expires_login', res.details.credentials.expires_in);
          localStorage.setItem("name", res.details.first_name + ' ' + res.details.last_name);
          this.router.navigate(['/dashboard']);
        }
      },
        (error) => {
          this.staticAlertShow = false;
          this.successMessage = error
        }
      );
    }
  }
}
